
.parentPurchase {
  width: 100px;
  height: 138px;
  border-radius: 12px;
  overflow: hidden;
}



