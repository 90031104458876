
.parent {
  width: 264px; 
  height: 363px;
  border-radius: 12px;
  overflow: hidden;
  cursor: pointer;
  margin-right: 2.08%;
  margin-bottom: 2.08%;
}

.child {
  width: 100%;
  height: 100%;
  border-radius: 12px;
  background-color: black; /* fallback color */
  background-image: url("../../assets/images/camisa.jpeg");
  background-position: center;
  background-size: 220%;
  transition: background-size 1s ease-in-out;
  position: relative;
  display: flex;
  align-items: flex-end;
}

.child:hover,
.child:focus {
  background-size: 250%;
  transition: all .5s;
}